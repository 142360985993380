<template>

  <div class="-mx-20px min-h-full">
    <!-- <el-alert
      :title="$t('head.dev')"
      type="warning"
      show-icon>
    </el-alert> -->
    <div style="padding: 20px">
      <Headers></Headers>
    </div>
    
    <div class="pt-0px">
      <div class="box token">
        <div v-for="(item, index) in list" :key="index">
          <div
            class="flex justify-between items-center token-item"
          >
            <span>{{ $t('head.balance') }} : {{ item.balance }}</span>
            <span class="token-name">{{ item.name }}</span>
          </div>
          <el-divider class="m-0" v-if="index != list.length - 1"></el-divider>
        </div>
      </div>
      <div class="box mt-20px" v-for="item in rlist" :key="item.rid">
        <!-- <el-radio-group v-model="rid" size="medium">
          <el-radio
            border
            :label="item.rid"
            v-for="item in rlist"
            :key="item.rid"
          >
            <div class="text-center">{{ item.title }}{{ $t('head.day') }}</div>
            <div class="text-center" style="font-size: 14px; color: #e3e3e3">
              x{{ item.x }}
            </div>
          </el-radio>
        </el-radio-group> -->
        <!-- <div class="flex justify-end balance">
          {{ $t('head.balance') }}:{{ currentObj.balance }}
        </div> -->
        <div class="flex justify-between">
          <span>{{ item.title }}{{ $t('head.day') }}</span>
          <span>{{item.y}}</span>
        </div>
        <div class="ipt-bg flex">
          <div class="flex items-center">
            <span class="coin">{{ currentObj.name }}</span>
          </div>
          <div class="flex-1">
            <el-input
              type="text"
              v-model="item.amount"
              class="w-full cu-input"
              placeholder="0"
              @input="
                item.amount = item.amount.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
              "
            ></el-input>
          </div>
        </div>
        <div class="flex justify-between">
          <div
            class="btn mt-20px flex-1"
            style="margin-right: 20px"
            v-loading="item.loading"
            element-loading-spinner="el-icon-loading"
            @click="onApprove(item)"
          >
            {{ $t('head.aa7') }}
          </div>
          <div
            class="btn mt-20px flex-1"
            v-loading="item.dploading"
            element-loading-spinner="el-icon-loading"
            @click="onDeposit(item)"
          >
            {{ $t('head.deposit') }}
          </div>
        </div>
      </div>
      <div class="box mt-20px">
        <div class="flex justify-between">
          <span>Deposit</span>
          <span>{{ Deposit }}</span>
        </div>
        <div class="flex justify-between">
          <span>Hash</span>
          <span>{{ Hash }}</span>
        </div>
        <div class="flex justify-between">
          <span>CountTime</span>
          <Coundown :longTime="downTime"></Coundown>
        </div>
        <div class="flex justify-between">
          <div
            class="btn mt-20px flex-1"
            v-loading="wloading"
            element-loading-spinner="el-icon-loading"
            @click="onWithdraw"
          >
            {{ $t('head.withdraw') }}
          </div>
        </div>
      </div>
    </div>
    <div style="height:30px"></div>
  </div>
</template>
<script>
import Coundown from '../components/coundown'
import Headers from '../components/headers.vue'
import mixin from '../mixins/index'
import { BigNumber } from 'bignumber.js'
export default {
  components: {
    Headers,
    Coundown,
  },
  mixins: [mixin],
  data() {
    return {
      newBlockNumber: 0,
      loading: false,
      dploading: false,
      wloading: false,
      lqLoading: false,
      dialogVisible: false,
      current: '',
      poolearn: '',
      Hash: '',
      Deposit: '',
      endBlock: 0,
      rlist: [
        {
          title: '30',
          x: 2,
          rid: 0,
          amount:'',
          loading:false,
          dploading:false,
          y:'200%',
        },
        {
          title: '180',
          x: 5,
          rid: 1,
          amount:'',
          loading:false,
          dploading:false,
          y:'500%',
        },
        {
          title: '360',
          x: 10,
          rid: 2,
          amount:'',
          loading:false,
          dploading:false,
          y:'1000%',
        },
      ],
      list: [
        {
          name: 'Cake-LP-UNC',
          tokenName: 'DAX',
          token: '',
          balance: 0,
          contract: null,
          pid: 0
        }
      ],
    }
  },
  computed: {
    currentObj() {
      return this.list.find(({ token }) => token == this.current)
    },
    downTime() {
      return (this.endBlock - this.newBlockNumber) * 3
    },
  },
  created() {
    this.list[0].token = this.pool.cakelpunc
    this.current = this.list[0].token
  },
  methods: {
    // 全部赎回
    onWithdraw() {
      this.wloading = true
      this.LP_contract.methods
        .withdraw(this.currentObj.pid)
        .send({
          from: this.global.account,
        })
        .then(() => {
          this.wloading = false
          this.$toast('SUCCESS')
          this.getdeposit()
        })
        .catch((err) => {
          this.wloading = false
          this.$toast(err)
        })
    },
    // 锁仓
    onDeposit(item) {
      if (item.amount == '' || item.amount <= 0)
        return this.$toast(this.$t('head.sl'))
      item.dploading = true
      this.LP_contract.methods
        .deposit(
          this.currentObj.pid,
          this.global.web3.utils.toWei(item.amount + '', 'ether'),
          item.rid
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          item.dploading = false
          this.$toast('SUCCESS')
        })
        .catch((err) => {
          item.dploading = false
          this.$toast(err)
        })
    },
    // 授权
    onApprove(item) {
      if (item.amount == '' || item.amount <= 0)
        return this.$toast(this.$t('head.sl'))

      const price = (item.amount * 10 ** 18 * 1.1).toFixed(0)

      item.loading = true

      this.currentObj.contract.methods
        .approve(
          this.pool.LPPool,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          item.loading = false
          this.$toast('APPROVE SUCCESS')
        })
        .catch((err) => {
          this.$toast(err)
          item.loading = false
        })
    },

    getdeposit() {
      this.LP_contract.methods
        .getDeposit(this.global.account, this.currentObj.pid)
        .call()
        .then((res) => {
          this.Hash = (res.amounthr / 1e18).toFixed(6)
          this.Deposit = (res.amountlp / 1e18).toFixed(6)
          this.endBlock = res.endblock
          if (this.Hash == 0) this.Hash = 0
          if (this.Deposit == 0) this.Deposit = 0
          if (this.endBlock == 0) this.endBlock = 0
        })
      // this.LP_contract.methods
      //   .poolEarned(this.global.account, this.currentObj.pid)
      //   .call()
      //   .then((res) => {
      //     this.poolearn = res / 1e18
      //   })
    },
  },
  watch: {
    'global.account'() {
      this.global.web3.eth.getBlockNumber().then((res) => {
        this.newBlockNumber = res
      })

      this.LP_contract = new this.global.web3.eth.Contract(
        this.poolabi.lppool_abi,
        this.pool.LPPool
      )
      this.getdeposit()
      this.list.map((e) => {
        e.contract = new this.global.web3.eth.Contract(
          this.poolabi.iphpool_abi2,
          e.token
        )
        e.contract.methods.balanceOf(this.global.account).call().then((res) => {
          e.balance = (res / 1e18).toFixed(3)
        })
      })
    },
  },
}
</script>


<style lang="scss" scoped>
.logo {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.token-name {
  font-weight: 600;
  font-size: 16px;
}
.token {
  &-item {
    height: 56px;
  }
}
.btn {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 10px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  font-size: 20px;
}
.m-0 {
  margin: 0;
}
.mt-20px {
  margin-top: 20px;
}
::v-deep .cu-input {
  .el-input__inner {
    text-align: right;
    // color: rgb(244, 238, 255);
    position: relative;
    font-weight: 500;
    outline: none;
    border: none;
    flex: 1 1 auto;
    background-color: transparent;
    font-size: 22px;
    font-weight: 600;
  }
}
.coin {
  margin-right: 8px;
  font-size: 16px;
  color: #333;
}
.ipt-bg {
  background: #fff;
  border-radius: 10px;
  height: 58px;
  line-height: 58px;
  padding: 0 14px;
  margin-top: 10px;
}
.text-center {
  text-align: center;
}
.balance {
  color: #85858d;
  font-size: 14px;
}
.text-right {
  text-align: right;
}
.flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.justify-end {
  justify-content: end;
}
.items-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.min-h-full {
  min-height: 100vh;
  box-sizing: border-box;
}
.w-full {
  width: 100%;
}
.-mx-20px {
  margin: -20px;
}
.pt-40px {
  padding-top: 40px;
}
.box {
  width: 370px;
  @apply bg-primary shadow-2xl shadow-primary/50;
  // box-shadow: 0px 0px 30px rgba(121 ,204, 216, 0.5);
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  box-sizing: border-box;
}

::v-deep .el-radio-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-radio {
    margin-right: 10px;
    width: 70px;
    // height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-radio__input {
      display: none;
    }
    .el-radio__label {
      font-size: 16px;
      color: #fff;
      padding: 0;
    }
  }
  .is-bordered {
    padding: 0;
  }
  .is-checked {
    border-color: #FFF;
    background: rgba(46, 209, 246, 0.4);
    color: rgb(26, 215, 249);
  }
}
</style>
