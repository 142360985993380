<template>
  <div class="timeCount-container">
    <div>
      {{ day }} D {{ this.hour }} H {{ this.minute }} H {{ this.second }} S
    </div>
  </div>
</template>
<script>
export default {
  name: 'timeCount',
  data() {
    return {
      endTime: 0,
      day: 0,
      hour: 0,
      minute: 0,
      second: 0,
      interval: null,
    }
  },
  //传值
  props: {
    //倒计时时间
    longTime: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    
    //结束时间
    this.endTime = Date.parse(new Date()) + this.longTime * 1000
    //开始倒计时
    this.countdown()
  },
  beforeDestroy() {
    //提前结束则销毁倒计时
    clearTimeout(this.interval)
  },
  methods: {
    countdown() {
      //计算运行时间
      const end = this.endTime
      
      const now = Date.parse(new Date())
      let msec = end - now
      if(msec<=0)  msec = 0
      //计算剩余时间
      this.day = parseInt(msec / (1000 * 60 * 60 * 24))
      this.hour = parseInt(msec / (1000 * 60 * 60) - this.day * 24)
      this.minute = parseInt(
        msec / (60 * 1000) - this.day * 24 * 60 - this.hour * 60
      )
      this.second = parseInt(
        msec / 1000 -
          this.day * 24 * 60 * 60 -
          this.hour * 60 * 60 -
          this.minute * 60
      )

      const that = this
      //结束则弹框
      if (msec === 0) {
        //
      } else {
        this.interval = setTimeout(that.countdown, 1000)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.timeCount-container {
  // box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}
</style>
